<template>
  <b-modal
    id="scanQris"
    ref="scanQris"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-header
    hide-footer
    size="md"
    centered
    @hidden="$emit('hidden'), loadingSubmit = false"
    @shown="failure = false, alreadyPaid = false"
  >
    <div class="container text-center text-black py-2 d-flex flex-column gap-15">
      <h2 class="capitalize m-0 font-bold">
        Scan QRIS
      </h2>
      <p class="m-0">
        Silahkan scan QRIS untuk melanjutkan pembayaran
      </p>

      <div class="d-flex flex-column items-center justify-center shadow py-2 px-[30px] gap-20 rounded-lg border-b-[10px] border-orange">
        <div class="d-flex self-stretch justify-between">
          <div class="d-flex gap-3">
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Qris-logo.svg"
              class="h-[38px]"
              alt="qris"
            >
            <div class="d-md-flex flex-column d-none items-start text-6">
              <span>QR COde Standar</span>
              <span>Pembayaran Nasional</span>
            </div>
          </div>
          <div class="d-flex flex-column text-6 text-right">
            <span>Bayar Sebelum</span>
            <span class="text-primary font-bold">{{ DAY_MONTH_YEAR(qrBillDetail.expire) }}</span>
            <span class="text-primary font-bold">{{ TIME(qrBillDetail.expire) }}</span>
          </div>
        </div>
        <h4 class="m-0 font-bold">
          KOMERCE.ID
        </h4>
        <div class="h-[200px] w-[200px] position-relative d-flex">
          <img
            :src="`https://api.qrserver.com/v1/create-qr-code/?data=${qrBillDetail.qrString}&size=200x200`"
            :style="loading ? 'filter: blur(4px)' : 'filter: blur(0)'"
            @load="loading = false"
          >
        </div>
        <p class="m-0 text-12">
          {{ IDR(qrBillDetail.amount) }}
        </p>
      </div>

      <button
        v-if="!alreadyPaid"
        @click="cancelPayment()"
      >
        Batalkan Pembayaran
      </button>
      <button
        class="button-primary d-flex justify-center py-[10px] gap-10 items-center text-8 font-bold"
        :disabled="loadingSubmit"
        @click="checkPaid()"
      >
        <div
          v-if="loadingSubmit"
          class="p-[5px] bg-light rounded-full"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/refresh-2.svg"
            class="h-[20px]"
          >
        </div>
        <span v-if="!loadingSubmit">Sudah Bayar</span>
      </button>
      <div
        v-if="failure"
        class="d-flex justify-center items-center gap-5"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          class="h-[15px]"
          alt="danger"
        >
        <span class="text-danger text-5">Pembayaran belum berhasil</span>
      </div>
      <div
        v-if="alreadyPaid"
        class="d-flex justify-center items-center gap-5"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
          class="h-[15px]"
          alt="danger"
        >
        <span class="text-danger text-5">Kamu sudah melakukan pembayaran</span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { DAY_MONTH_YEAR, TIME } from '@/libs/formatDate'
import { IDR } from '@/libs/currency'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    qrBillDetail: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: true,
      loadingSubmit: false,
      failure: false,
      alreadyPaid: false,
      DAY_MONTH_YEAR,
      TIME,
      IDR,
    }
  },
  methods: {
    async checkPaid() {
      this.loadingSubmit = true
      this.failure = false
      this.alreadyPaid = false
      try {
        const response = await this.$store.dispatch('saldo/getQrCode', this.qrBillDetail.qrId)
        const { data } = response

        if (!data.status) throw data
        this.loadingSubmit = false

        if (data.data) {
          const { status_payment } = data.data

          /* eslint-disable camelcase */
          if (status_payment === 'WAITING') this.failure = true
          else if (status_payment === 'EXPIRED') this.$emit('paymentSuccess', 'Berhasil membatalkan pembayaran')
          else this.$emit('paymentSuccess', 'Pembayaran berhasil')
        }
      } catch (e) {
        this.$swal({
          title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
          text: e.message,
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    async cancelPayment() {
      this.loadingSubmit = true
      this.failure = false
      this.alreadyPaid = false
      this.$store.dispatch('saldo/cancelQrBill', this.qrBillDetail.qrId)
        .then(async response => {
          const { data } = await response
          if (data.code === 200) {
            this.toastSuccess('Berhasil membatalkan pembayaran')

            this.$bvModal.hide('modalPaymentExist')
            this.$bvModal.hide('scanQris')
            this.$bvModal.hide('modalTopUp')
          }
          if (!data.status) throw data
        })
        .catch(({ response }) => {
          this.loadingSubmit = false

          if (response.status === 400) {
            this.alreadyPaid = true
          } else {
            this.$swal({
              title: '<span class="font-semibold h4">Pembatalan Pembayaran Gagal</span>',
              text: response.data.data,
              // eslint-disable-next-line global-require
              imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
              confirmButtonText: 'Oke',
              confirmButtonClass: 'btn btn-primary',
            })
          }
        })
    },
    toastSuccess(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'SuccessIcon',
          text,
          variant: 'success',
        },
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
$primary: #F95031;
$orange: #ff6a3a;
$grayscale: #c2c2c2;
$black--text: #222222;
$salmon: #FCD4BE;
$blue--text: #08A0F7;

.border-orange {
  border-bottom-color: #F95031;
}
.text {
  @for $i from 1 through 16 {
    &-#{$i} {
      font-size: #{$i * 2}px;
    }
  }
}
.gap {
  @for $i from 1 through 32 {
    &-#{$i} {
      gap: #{$i}px;
    }
  }
}
.button-primary {
  background-color: $primary;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
  text-align: center;
}

.button-primary:disabled {
  opacity: .5;
}

</style>
