<template>
  <BModal
    id="paymentMethod"
    ref="paymentMethod"
    no-close-on-esc
    hide-header-close
    hide-header
    hide-footer
    size="md"
    centered
  >
    <div class="container text-center py-3">
      <div class="d-flex flex-column capitalize gap-15">
        <h2 class="capitalize m-0 font-bold">
          metode pembayaran
        </h2>
        <p class="m-0">
          Silahkan pilih metode pembayaran yang anda inginkan
        </p>

        <div class="d-flex justify-between">
          <button
            class="d-flex flex-column items-center justify-center method shadow"
            @click="$emit('methodSelected', 'bank')"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Bank-metode-lain.svg"
              class="mt-auto"
              alt="bank"
            >
            <h4 class="m-0 font-bold">
              Metode Lain
            </h4>
            <div class="d-flex flex-column justify-center mt-auto class h-[55px] border-b-[10px] w-100 border-t-[1px] border-orange text-5 text-center px-2 align-center">
              <span>dikenakan biaya admin sebesar <span class="text-primary">Rp 1.000</span></span>
            </div>
          </button>

          <!-- disabled -->
          <button
            class="d-flex flex-column items-center justify-center method shadow cursor-not-allowed"
            :disabled="isWeekend"
            @click="$emit('methodSelected', 'qris')"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/Qris-logo.svg"
              class="mt-auto"
              alt="qris"
            >
            <h4 class="m-0 font-bold">
              Gunakan QRIS
            </h4>
            <div
              :class="isWeekend ? 'border-gray' : 'border-orange'"
              class="d-flex flex-column justify-center mt-auto class h-[55px] border-b-[10px] w-100 border-t-[1px] text-5 text-center px-2 align-center"
            >
              {{ isWeekend ? 'QRIS dinon-aktifkan mulai' : 'tidak ada biaya admin' }}
              <div v-if="isWeekend">
                {{ messageTopup }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    isWeekend: {
      type: Boolean,
      required: true,
    },
    messageTopup: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
button.method {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
}
.border-orange {
  border-bottom-color: #F95031;
}
.text {
  @for $i from 1 through 16 {
    &-#{$i} {
      font-size: #{$i * 2}px;
    }
  }
}
.gap {
  @for $i from 1 through 32 {
    &-#{$i} {
      gap: #{$i}px;
    }
  }
}

</style>
