<template>
  <b-modal
    id="modalPaymentExist"
    ref="modalPaymentExist"
    no-close-on-esc
    hide-header-close
    hide-header
    hide-footer
    size="md"
    centered
    @shown="loading = false"
  >
    <div class="text-center my-1 p-[10px]">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto w-[100px]"
      >
      <p class="text-black my-2 text-9">
        Kamu masih memiliki pembayaran Top Up yang belum diselesaikan!
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 100%"
          :disabled="loading"
          @click="$emit('cancelPayment'), loading = true"
        >
          Batalkan Pembayaran
        </BButton>
        <BButton
          size="md"
          variant="primary"
          style="width: 100%"
          :disabled="loading"
          block
          @click="$emit('continuePayment'), loading = true"
        >
          Bayar Sekarang
        </BButton>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.text {
  @for $i from 1 through 16 {
    &-#{$i} {
      font-size: #{$i * 2}px;
    }
  }
}
.gap {
  @for $i from 1 through 32 {
    &-#{$i} {
      gap: #{$i}px;
    }
  }
}

</style>
